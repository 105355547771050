import * as React from 'react';
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image"
import { rhythm } from '../utils/typography';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import { useState } from 'react';


export default ({ location }) => {
  let [r, setR] = useState(false);
  let [m, setM] = useState("Click to reveal");
  const data = useStaticQuery(graphql`
    query AboutQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  return (
    <Layout location={location} title="About">
      <SEO
        title={"About"}
        description={"About me"}
      />
      <div>
        <StaticImage src="../../content/assets/profile-pic.png"
          alt="Søren Alsbjerg Hørup"
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
            float: 'left',
          }}
          placeholder="blurred"
          imgStyle={{
            borderRadius: `50%`,
          }}

        />
        <b>Søren Alsbjerg Hørup</b>, Software Engineer from Denmark with a keen interest in technology of any kind. Implements solutions in nearly any language, be it low-level programming for embedded devices or high-level programming for the web.
        <br />
        <br />
        I strive to learn something new everyday and sharing newly gained knowledge with my peers. As they say, you only know something well enough… if you can explain it to others.
        <br />
        <br />
        You can follow me on <a href={`https://twitter.com/${data.site.siteMetadata.social.twitter}`}>
          Twitter
        </a>
        <br />
        You can find me on <a href="https://github.com/horup">Github</a>
        <br />
        You can e-mail me on <span style={{margin:'3px', padding:'5px', border: '1px dotted black', cursor: r ? '' : 'pointer' }} onClick={() => {
          let user = "horup";
          let host = "deepstacker.com";
          let e = user + '@' + host;
          setM(e);
          setR(true);
        }}>{m}</span>
        <br /> 
      </div>
    </Layout>
  )
}